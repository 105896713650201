import { Avatar, Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import moment from 'moment';
import heartIcon from '../../../../assets/heart-icon.svg';

interface ReplyCommentsType {
    replies: replyType;
    createdByAuthorId?: string;
}

interface replyType {
    replies: [];
}

interface ItemType {
    createdByUsername: string;
    id: string;
    content: string;
    createdByUserId: string;
    createdAt: string;
    totalLikes: number;
    createdByUserProfileUrl: string;
}

function ReplyComments({ replies, createdByAuthorId }: ReplyCommentsType) {
    const data = replies.replies;

    const getTime = (date: string) => {
        const present = moment();
        const createdTime = moment(date);
        const duration = moment.duration(present.diff(createdTime));

        //get day
        const days = Math.trunc(duration.asDays());
        //get hours
        const hours = Math.trunc(duration.hours());
        //get minutes
        const minutes = Math.trunc(duration.minutes());

        if (days !== 0) {
            return days.toString() + 'd';
        } else if (days === 0 && hours !== 0) {
            return hours.toString() + 'h';
        } else if (days === 0 && hours === 0 && minutes !== 0) {
            return minutes.toString() + 'm';
        } else {
            return 'seconds ago';
        }
    };

    return (
        <Box mt={4}>
            {data.map((item: ItemType) => {
                const time = getTime(item.createdAt);
                const totalLikes = item.totalLikes;
                const userAvatar = item.createdByUserProfileUrl;
                const createdByUserId = item.createdByUserId;

                return (
                    <Box key={item.id} mb={4} _last={{ mb: 1 }}>
                        <Flex alignItems="start">
                            <Avatar width={'40px'} height={'40px'} name={item.createdByUsername} src={userAvatar ?? ''} mr={2} />

                            <Box>
                                <Heading fontSize="1rem" mr={2} wordBreak={'break-word'}>
                                    {item.createdByUsername}{' '}
                                    {createdByUserId === createdByAuthorId && (
                                        <Text as={'span'} fontWeight={500} color={'#818181'}>
                                            {' '}
                                            - Author
                                        </Text>
                                    )}
                                </Heading>
                                <Text fontSize="0.9rem" color="#818181">
                                    {time}
                                </Text>
                            </Box>
                        </Flex>
                        <Text mt={2} fontSize="1rem">
                            {item.content}
                        </Text>

                        {totalLikes > 0 && (
                            <Flex alignItems="center" mt={2}>
                                <Text mr={1}>{totalLikes}</Text>
                                <Image src={heartIcon} width={'1rem'} />
                            </Flex>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
}

export default ReplyComments;
