import { Box } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './OpenSharedLink.scss';
import Comments from './components/Comments/Comments';
import Media from './components/Media/Media';

interface PostItem {
    id: string;
    mediaUrl: string;
    mediaThumbnailUrl: string;
}

export interface Question {
    content: string;
}

interface tagType {
    name: string;
}

export interface PostDetail {
    createdByUserProfileUrl: string;
    createdByUsername: string;
    createdAt: string;
    question: Question;
    tags: tagType[];
    createdByUserId: string;
}

function OpenSharedLink() {
    const [postMedia, setPostMedia] = useState<PostItem[]>([]);
    const [postItems, setPostItems] = useState<PostDetail>();
    const [comments, setComments] = useState<[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const api = process.env.REACT_APP_SHARED_POST_API;

    const getSharedPost = async () => {
        if (api) {
            const response = await axios(api + params.postId);
            const post = response.data.data.postItems;
            setPostMedia(post);
        }
    };

    const getPostDetails = async () => {
        if (api) {
            const response = await axios(api + params.postId);
            setPostItems(response.data.data);
            console.log(postItems);
        }
    };

    const getSharedPostComments = async () => {
        if (api) {
            const response = await axios(api + params.postId + '/comments');
            const data = response.data.data.comments;
            setComments(data);
        }
    };

    useEffect(() => {
        const CallFunction = async () => {
            try {
                setIsLoading(true);
                await getSharedPost();
                await getSharedPostComments();
                await getPostDetails();
                setIsLoading(false);
            } catch (err) {
                navigate('*');
            }
        };

        CallFunction();
    }, []);

    return (
        <>
            <Box className="open-shared-link-wrapper" width="60%" mx="auto" height={'100vh'}>
                <Media postMedia={postMedia} isLoading={isLoading} />
                <Comments
                    comments={comments}
                    isLoading={isLoading}
                    content={postItems?.question.content}
                    createdAt={postItems?.createdAt}
                    createdByUserProfileUrl={postItems?.createdByUserProfileUrl}
                    createdByUsername={postItems?.createdByUsername}
                    tags={postItems?.tags}
                    createdByUserId={postItems?.createdByUserId}
                />
            </Box>
        </>
    );
}

export default OpenSharedLink;
