import { Box, Flex, ListItem, UnorderedList } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

import './Header.scss';
import clsx from 'clsx';

function Header() {
  const { pathname } = useLocation();

  return (
    <Box
      className='terms-and-policy__header'
      as='header'
      height={'402px'}
      pt={'50px'}
    >
      <Flex
        className='header__nav'
        alignContent={'center'}
        justifyContent={'space-between'}
        width={'1600px'}
        mx={'auto'}
      >
        <div className='font-bold text-5xl select-none text-white'>
          styledial
        </div>
        {/* <Image className='header__logo' src={styledialLogo} width={'125px'} /> */}

        <UnorderedList
          className='header__nav-list items-center'
          ml={'0'}
          listStyleType={'none'}
          color={'#FFF'}
          display={'flex'}
          gap={'44px'}
          fontWeight={'400'}
          lineHeight={'normal'}
          letterSpacing={'-0.08px'}
        >
          <ListItem
            className={clsx(
              {
                underline: pathname === '/terms-of-use',
              },
              'text-base'
            )}
          >
            <Link to='/terms-of-use'>Terms of Use</Link>
          </ListItem>

          <ListItem
            className={clsx(
              {
                underline: pathname === '/privacy-policy',
              },
              'text-base'
            )}
          >
            <Link to='/privacy-policy'>Privacy Policy</Link>
          </ListItem>

          <ListItem>
            <Link className='text-base' to='/'>
              Download styledial
            </Link>
          </ListItem>
        </UnorderedList>
      </Flex>

      <Box
        className='header__page-title'
        as='h1'
        color={'#FFF'}
        fontSize={'64px'}
        fontWeight={'400'}
        lineHeight={'normal'}
        letterSpacing={'-0.32px'}
        mt={'81px'}
        textAlign={'center'}
        width={'1600px'}
        mx={'auto'}
      >
        {pathname === '/terms-of-use' ? 'Terms of Use' : 'Privacy Policy'}
      </Box>
    </Box>
  );
}

export default Header;
