import { Skeleton } from '@chakra-ui/react';
import { useState } from 'react';
import Slider from 'react-slick';
import EachItem from './EachItem';
import './Media.scss';

interface MediaProps {
    postMedia: PostItemsProps[];
    isLoading: boolean;
}

interface PostItemsProps {
    id: string;
    mediaUrl: string;
}

function Media({ postMedia, isLoading }: MediaProps) {
    const [curIdx, setCurIdx] = useState(0);

    return (
        <Skeleton className="shared-media" height={'70%'} isLoaded={!isLoading}>
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />

            <Slider
                swipe={true}
                centerPadding="0"
                slidesToShow={1}
                dots={false}
                infinite
                beforeChange={(cur, next) => {
                    setCurIdx(next);
                }}
                centerMode>
                {postMedia.map((item: PostItemsProps, index) => {
                    return <EachItem key={index} MediaLink={item.mediaUrl} curIdx={curIdx} />;
                })}
            </Slider>
        </Skeleton>
    );
}

export default Media;
