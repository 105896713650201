import { createBrowserRouter } from "react-router-dom";

import LandingPage from "../pages/LandingPage/LandingPage";
import NoMatch from "../pages/NoMatch/NoMatch";
import OpenSharedLink from "../pages/OpenSharedLink/OpenSharedLink";
import TermsAndPolicy from "../pages/TermsAndPolicy/TermsAndPolicy";
import PrivacyPolicy from "../pages/TermsAndPolicy/components/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "../pages/TermsAndPolicy/components/TermsOfUse/TermsOfUse";
import TermsAndPolicyInApp from "../pages/TermsAndPolicy/TermsAndPoicyInApp";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <NoMatch />,
    children: [
      {
        index: true,
        path: "/",
        element: <LandingPage />,
        // children: [
        //     {
        //         path: '/home/posts/:postId',
        //         element: <OpenSharedLink />
        //     }
        // ]
      },
      {
        path: "/",
        element: <TermsAndPolicy />,
        children: [
          {
            path: "/terms-of-use",
            element: <TermsOfUse />,
          },
          {
            path: "/privacy-policy",
            element: <PrivacyPolicy />,
          },
        ],
      },
      {
        path: "/",
        element: <TermsAndPolicyInApp />,
        children: [
          {
            path: "/terms-of-use/app",
            element: <TermsOfUse />,
          },
          {
            path: "/privacy-policy/app",
            element: <PrivacyPolicy />,
          },
        ],
      },

      {
        path: "/shared-link",
        element: <OpenSharedLink />,
      },
    ],
  },
]);

export { router };
