import { Box } from '@chakra-ui/react';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

interface EachItemType {
    MediaLink: string;
    key: number;
    curIdx: number;
}

interface vidPropsType {
    id: number;
    src: string;
    play: boolean;
}

function EachItem({ MediaLink, curIdx, key }: EachItemType) {
    const [isVideo, setIsVideo] = useState(false);

    const isVideoUrl = useCallback(async () => {
        try {
            const data = await axios.get(MediaLink);

            setIsVideo(data.headers['content-type'].includes('video'));
        } catch (error) {
            console.error(error);
        }
    }, [MediaLink]);

    useEffect(() => {
        isVideoUrl();
    }, []);

    const Vid = (props: vidPropsType) => {
        const vidRef = React.createRef<HTMLVideoElement>();
        useEffect(() => {
            if (props.play) {
                vidRef.current?.play();
                const active = vidRef.current?.closest('.slick-active');

                if (!active) {
                    vidRef.current?.pause();
                }
            } else {
                vidRef.current?.pause();
            }
        }, [props.play]);

        return (
            <video className={`video-${props.id}`} ref={vidRef} width="400" controls style={{ margin: '0px auto ', height: '100%' }}>
                <source src={props.src} type="video/mp4" />
            </video>
        );
    };

    return (
        <Box height={'100%'} backgroundColor={'black'}>
            {isVideo ? (
                <Vid id={key} src={MediaLink} play={key === curIdx} />
            ) : (
                <Box
                    className="each-item__image"
                    height={'100%'}
                    position="relative"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="contain"
                    backgroundImage={`url(${MediaLink})`}
                />
            )}
        </Box>
    );
}

export default EachItem;
