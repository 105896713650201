import { Avatar, Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import heartIcon from '../../../../assets/heart-icon.svg';
import ReplyComments from '../ReplyComments/ReplyComments';

interface EachCommentType {
    userAvatar: string | null;
    data: ItemType;
    time: string;
    username: string;
    createdByUserId: string;
    createdByAuthorId?: string;
}

interface ItemType {
    createdByUsername: string;
    id: string;
    content: string;
    createdByUserId: string;
    createdAt: string;
    replies: replyType;
    totalLikes: number;
}

interface replyType {
    replies: [];
}

function EachComment({ data, time, userAvatar, username, createdByUserId, createdByAuthorId }: EachCommentType) {
    const [showReplies, setShowReplies] = useState<boolean>(false);

    const replies = data.replies;
    const totalLikes = data.totalLikes;

    return (
        <Box key={data.id} p={4} _hover={{ bgColor: '#ECEAFF' }}>
            <Flex alignItems={'start'}>
                <Avatar width={'40px'} height={'40px'} name={username} mr={2} src={userAvatar ?? ''} />
                <Box>
                    <Heading fontSize="1rem" mr={2} wordBreak={'break-word'}>
                        {data.createdByUsername}{' '}
                        {createdByAuthorId === createdByUserId && (
                            <Text as={'span'} fontWeight={500} color={'#818181'}>
                                - Author
                            </Text>
                        )}
                    </Heading>
                    <Text fontSize="0.9rem" color="#818181">
                        {time}
                    </Text>
                </Box>
            </Flex>
            <Text mt={2} fontSize="1rem">
                {data.content}
            </Text>
            {replies.replies.length > 0 && (
                <Box mt={2}>
                    {!showReplies ? (
                        <Flex>
                            {totalLikes > 0 && (
                                <Flex alignItems="center" mr={3}>
                                    <Text mr={1}>{totalLikes}</Text>
                                    <Image src={heartIcon} width={'1rem'} />
                                </Flex>
                            )}
                            <Button variant={'link'} fontSize={'0.8rem'} onClick={() => setShowReplies(true)}>
                                Show replies
                            </Button>
                        </Flex>
                    ) : (
                        <Box>
                            {totalLikes > 0 && (
                                <Flex alignItems="center" mr={3}>
                                    <Text mr={1}>{totalLikes}</Text>
                                    <Image src={heartIcon} width={'1rem'} />
                                </Flex>
                            )}

                            <Box pl={10}>
                                <ReplyComments replies={replies} createdByAuthorId={createdByAuthorId} />
                                <Button variant={'link'} fontSize={'0.8rem'} onClick={() => setShowReplies(false)}>
                                    Hide replies
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
}

export default EachComment;
