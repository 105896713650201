import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import HeaderInApp from "./components/Header/HeaderInApp";

const TermsAndPolicyInApp = () => {
  return (
    <Box>
      <HeaderInApp />
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default TermsAndPolicyInApp;
