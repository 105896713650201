import { Link, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react';

const PrivacyPolicyContent = {
  '1.	Our Privacy Philosophy': (
    <Text>
      {
        'We value the trust that you have placed in our organization and are committed to prioritizing commitment to maintaining the accuracy, confidentiality, and security of personal information. Read through this policy so you understand how we use, share and store your data.'
      }
    </Text>
  ),
  '2. Our Policy': (
    <Stack spacing={'20px'}>
      <Text>
        In this Policy “we” or “us” refers to STYLEDIAL Inc. (“STYLEDIAL”) and
        our subsidiaries and affiliates. All services mentioned are services
        provided by STYLEDIAL.
      </Text>

      <Text>
        “You” refers to anyone who visits our website(s), our customers, and
        anyone who engages with our services. It’s our duty to inform you about
        what we’re doing with your personal information and why, and ensure you
        are properly informed so you can give us meaningful consent.
      </Text>

      <Text>
        For the purposes of this Policy, “personal information” means
        information that can identify an individual directly or is information
        about an identifiable individual. This does not include information that
        is used solely for the purpose of communicating or facilitating
        communication with an individual in relation to their employment,
        business, or profession.
      </Text>
    </Stack>
  ),
  '3.	Agreeing to the Terms of Our Privacy Policy': (
    <Stack spacing={'20px'}>
      <Text>
        By providing personal information to STYLEDIAL, using our website or
        engaging with our social media, you are consenting to our use,
        collection and disclosure of your personal information in accordance
        with the terms of this Policy. Providing personal information or
        authorizing a third party to disclose personal information to us
        signifies your consent to STYLEDIAL’s collection, use and disclosure of
        personal information in accordance with this Policy.
      </Text>

      <Text>
        You have the right to withdraw your consent from us collecting and using
        your personal information, but this may limit our ability to provide you
        with our services or products, if applicable. See below for details
        around how to withdraw your consent.
      </Text>
    </Stack>
  ),
  '4.	Types of Information Collected': (
    <Stack spacing={'20px'}>
      <Text>
        The type of information that may be collected includes but is not
        limited to:
      </Text>
      <UnorderedList className='policy-list flex flex-col gap-4' pl={'30px'}>
        <ListItem>
          <span className='font-semibold'>Contact information:</span> including
          your name, mailing address, email address, telephone number;
        </ListItem>

        <ListItem>
          <span className='font-semibold'>Payment information:</span> including
          your bank account information, billing address, or other banking and
          financial information required to complete your order;
        </ListItem>

        <ListItem>
          <span className='font-semibold'>Profile information:</span> includes
          your username and password, purchases or orders made by you, your
          interests, preferences, feedback and survey responses;
        </ListItem>

        <ListItem>
          <span className='font-semibold'>Chat Information:</span> When you post
          information on a chat, message board or communicate with us about
          issues, we collect the information you provide.
        </ListItem>

        <ListItem>
          <span className='font-semibold'>Transaction information:</span>{' '}
          includes details about payments to and from you and other details of
          products and services you have purchased from us, including contents
          and dollar values of any packages for delivery;
        </ListItem>

        <ListItem>
          <span className='font-semibold'>Health information:</span> includes
          limited gynecologic information including menstrual interval,
          duration, dates, and other health information you voluntarily provide;
        </ListItem>

        <ListItem>
          <span className='font-semibold pr-1'>
            Marketing and communications information:
          </span>
          includes your preferences in receiving marketing from us and third
          parties and your communication preferences;
        </ListItem>

        <ListItem>
          <span className='font-semibold'>Computer information:</span> this
          includes information like device type, device identifier, IP address,
          MAC address, location, browser type, operating system, duration of
          use, user behaviour, length of time spent on the services, and
          frequency of use;
        </ListItem>

        <ListItem>
          <span className='font-semibold'>Other information</span> you
          voluntarily provide to us, our employees, or our contractors.
        </ListItem>
      </UnorderedList>
      <Text>
        We won’t take more information than we need, and you decide how and what
        you want to share.
      </Text>
    </Stack>
  ),
  '5.	Why We Collect Your Information': (
    <Stack spacing={'20px'}>
      <Text>
        At STYLEDIAL, we collect and use personal information for reasons like:
      </Text>
      <UnorderedList className='policy-list flex flex-col gap-4' pl={'30px'}>
        <ListItem>
          <span className='font-semibold'>Fashion sizing information:</span> to
          provide you with more useful recommendations of content that may be
          more suitable for your size;
        </ListItem>

        <ListItem>
          <span className='font-semibold pr-1'>
            Facilitate discussion among members and understanding of issues:
          </span>
          It helps to know others may be going through similar issues or
          challenges. If you are communicating on in a public chat or post on a
          message board, that information will be seen by others and used for
          understanding issues people are facing.
        </ListItem>

        <ListItem>
          <span className='font-semibold'>Fulfillment of orders:</span> to
          fulfill orders that you have placed with us.
        </ListItem>

        <ListItem>
          <span className='font-semibold pr-1'>
            Business and website operations:
          </span>
          to administer and protect our business and this Website, including
          troubleshooting, data analysis, testing, system maintenance, support,
          reporting and hosting of data.
        </ListItem>

        <ListItem>
          <span className='font-semibold'>Marketing and advertising:</span> to
          be presented on our Website. We may also administer surveys, contests,
          draws, raffles, offer tailored content, and other promotional
          materials to you based on your interests.
        </ListItem>

        <ListItem>
          <span className='font-semibold pr-1'>Regulatory requirements:</span>
          complying generally with all laws and applicable regulatory
          requirements; and
        </ListItem>

        <ListItem>
          <span className='font-semibold pr-1'>
            Business development and market research:
          </span>
          we use data to understand demographics, interests, usage patterns, and
          other characteristics of customers and to track and analyze trends and
          patterns.
        </ListItem>
      </UnorderedList>
      <Text>
        We will obtain your consent before proceeding with any other purpose not
        identified in this Policy.
      </Text>
    </Stack>
  ),
  '6.	How We Collect Your Information': (
    <Stack spacing={'20px'}>
      <Text>
        Personal information may be collected in a number of ways, including:
        through our mobile application, website, in person, by mail, by email,
        from our applications or other software, and from third parties whom you
        have authorized to disclose personal information to us.
      </Text>
      <Text>
        If you provide information about a third party or authorize a third
        party to do so, we will assume you have taken proper measures to obtain
        informed consent.
      </Text>
      <Text className='flex flex-col gap-3'>
        <span className='font-semibold italic'>a) Cookies</span>
        <span>
          We may also collect information through “cookies” on our website.
          Cookies are small text files inside your computer that contain a
          unique identifier, allowing us to recognize you when you visit our
          website again. This helps us tailor our content to meet your needs and
          for advertising purposes. You may refuse to accept cookies by
          activating the setting on your browser which allows you to refuse the
          setting of cookies. However, if you select this setting you may be
          unable to access certain parts of our Website.
        </span>
      </Text>
      <Text className='flex flex-col gap-3'>
        <span className='font-semibold italic'>b) Social media</span>
        <span>
          If you post a review, make a comment, or otherwise submit personal
          information on a public forum such as social media accounts or public
          forums on our Website or other applications, your communications may
          be viewable by the public.
        </span>
      </Text>
    </Stack>
  ),
  '7.	Who We Share Your Information With': (
    <Stack spacing={'20px'}>
      <Text>
        STYLEDIAL may share your information with third parties to support
        STYLEDIAL with providing our services and products to you and
        administering our business and for such other purposes as set forth
        below, including:
      </Text>
      <UnorderedList className='policy-list flex flex-col gap-4' pl={'30px'}>
        <ListItem>
          <span className='font-semibold'>Contractors and affiliates:</span>{' '}
          companies we use for storage, processing, and delivery of services;
        </ListItem>

        <ListItem>
          <span className='font-semibold pr-1'>
            Administrative and technical support:
          </span>
          including cloud storage providers, IT support, data analytics
          providers;
        </ListItem>

        <ListItem>
          <span className='font-semibold pr-1'>
            Public media and marketing:
          </span>
          including our affiliated media networks and companies we use for
          public broadcasting, advertising, marketing, public relations, press
          releases; and
        </ListItem>

        <ListItem>
          <span className='font-semibold pr-1'>
            Financial institutions and payment processors:
          </span>
          we do not store your banking or other financial information in
          connection with the fulfillment of orders, this is shared directly
          with our third-party financial institution and payment processors as
          applicable.
        </ListItem>
      </UnorderedList>
      <Text>
        We may also disclose personal information in situations where we are
        legally required or permitted to do so. These situations may include
        criminal investigations, government tax reporting requirements, court
        orders, or instances where we believe the rights and safety of others
        may be at risk.
      </Text>
      <Text>
        If you believe that a third party has inappropriately disclosed your
        personal information to us, please contact that third party directly. If
        the third party does not sufficiently respond to your inquiries, please
        let us know immediately.
      </Text>
    </Stack>
  ),
  '8.	Accuracy': (
    <Stack spacing={'20px'}>
      <Text>
        We make every reasonable effort to keep your personal information as
        accurate, complete and up-to-date as necessary. If desired, you may
        verify the accuracy and completeness of your personal information in our
        records.
      </Text>
      <Text>
        Despite our efforts, errors sometimes do occur. Should you identify any
        incorrect or out-of-date information in your file, we will remedy any
        such errors on a timely basis. In the event that inaccurate information
        is mistakenly sent to a third party, we will communicate relevant
        changes to the third party where appropriate.
      </Text>
    </Stack>
  ),
  '9.	Protecting Your Personal Information': (
    <Stack spacing={'20px'}>
      <Text>
        Our employees and contractors understand the importance of keeping your
        information private. All employees and contractors are expected to
        maintain the confidentiality of personal information.
      </Text>
      <Text>
        To ensure that your personal information is protected, we utilize
        security safeguards that are on par with the industry standard. We keep
        electronic files in a secured environment with restricted access and
        use.
      </Text>
      <Text>
        Despite all of the foregoing security measures, online storage and
        communicating online is not one hundred percent secure. We encourage all
        individuals to provide only the information they are comfortable with
        providing a third party, keep watch for communications that are
        suspicious, report any suspicious activity to us as soon as possible. We
        as a community can help keep us all safer.
      </Text>
    </Stack>
  ),
  '10.	Opting-out': (
    <Stack spacing={'20px'}>
      <Text>
        You may withdraw your consent for our use and collection of your
        personal information at any time, however this may limit our ability to
        provide services or products to you, if applicable.
      </Text>
      <Text>
        To withdraw consent, please contact us at the contact information set
        out at the end of this Policy. Any withdrawal of consent will apply
        thereafter and not to information handling practice that have been
        previously undertaken based on prior consent.
      </Text>
      <Text>
        You may opt-out of use of cookies through your browser settings. You can
        opt-out of targeted ads served via specific third-party vendors by
        visiting the Digital Advertising Alliance’s{' '}
        <Link
          href='https://optout.aboutads.info/?c=2&lang=EN#!/'
          style={{ textDecoration: 'underline' }}
          isExternal
        >
          Opt-Out
        </Link>{' '}
        page or the Network Advertising Initiative’s{' '}
        <Link
          href='https://optout.networkadvertising.org/?c=1'
          style={{ textDecoration: 'underline' }}
          isExternal
        >
          Opt-Out
        </Link>{' '}
        page.
      </Text>
    </Stack>
  ),
  '11. Updating this Privacy Policy': (
    <Text>
      STYLEDIAL strives to evolve to meet our applicant’s needs and
      expectations, and that means our services may change and how we offer our
      products may change. With that in mind, we review our privacy practices
      from time to time, and our information handling practices may change. If
      STYLEDIAL makes a material change to this Policy, we will provide a
      revised version on our Website and set out the date on which changes were
      made. Changes shall take effect upon notification of such changes. You may
      determine when this Policy was last updated by referring to the date found
      at the bottom of this Policy. If at any point you do not agree with the
      terms of this Policy, you must not use STYLEDIAL platform, Website or
      services.
    </Text>
  ),
  '12. Website and Apps Governed by this Privacy Policy': (
    <Text>
      Our Website and any of our applications available from our Website or
      third-party platforms are governed by the provisions and practices stated
      in this Policy. Our Website and such applications may contain links to
      third-party sites or applications that are not governed by this Policy.
      Although we endeavour to only link to sites or applications that share our
      commitment to your privacy, please be aware that this Policy will no
      longer apply once you leave our Website or such applications, and that we
      are not responsible for the privacy practices of third-party sites or
      applications. We therefore suggest that you closely examine the respective
      privacy policies of third-party sites and applications to learn how they
      collect, use and disclose your personal information.
    </Text>
  ),
  '13. Governing Law': (
    <Text>
      This Policy and all related matters shall be interpreted and construed in
      accordance with the laws of the Province of Ontario and the applicable
      federal laws of Canada.
    </Text>
  ),
  '14. Personal Information Outside of Canada': (
    <Text>
      Personal information provided to our service providers may be stored
      outside of Ontario and outside of Canada. You acknowledge and agree that,
      as a result, your personal information may be processed, used, stored or
      accessed in other jurisdictions and may be subject to the laws of those
      jurisdictions. For example, information may be disclosed in response to
      valid demands or requests from government authorities, courts, or law
      enforcement in other countries.
    </Text>
  ),
  '15. Addressing Your Inquiries and Concerns': (
    <Stack>
      <Text>
        We are happy to provide you with a copy of this Policy and to discuss
        any of its content with you.
      </Text>
      <Text>
        Please direct all questions or enquiries about this Policy to:
      </Text>
      <Text>Email: info@styledial.com</Text>
      <Text>Last revised Wednesday, March 6, 2024</Text>
    </Stack>
  ),
};

export default PrivacyPolicyContent;
