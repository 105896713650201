import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import ErrorPicture from '../../assets/404-error.webp';
import './NoMatch.scss';

function NoMatch() {
  const navigate = useNavigate();

  return (
    <Flex
      className='error-page-wrapper'
      width='100vw'
      height='100vh'
      justifyContent='space-evenly'
      alignItems='center'
    >
      <Box>
        <Box className='error-page__message-container' mb={'2.2rem'}>
          <Heading
            className='error-page__text-center error-page__heading'
            fontSize={'3.5rem'}
          >
            Opps!
          </Heading>
          <Heading
            className='error-page__text-center error-page__heading'
            fontSize={'3.5rem'}
          >
            We have a problem!
          </Heading>
          <Heading
            className='error-page__text-center error-page__heading'
            fontSize={'3.5rem'}
          >
            Be back soon.
          </Heading>
        </Box>

        <Text
          className='error-page__text-center error-page__text'
          fontSize={'1.5rem'}
          mb={'1rem'}
        >
          How would you like to proceed?
        </Text>

        <Button
          className='error-page__back-home-btn error-page__text'
          onClick={() => navigate('/')}
          fontSize={'1.4rem'}
          fontWeight={500}
          colorScheme='blue'
          mb={'3rem'}
        >
          Go back to Home
        </Button>

        <Divider className='error-page__divider' mb={'2.5rem'} />

        {/* <Stack className="error-page__helpful-links" fontSize={'1.1rem'}>
                    <Text className="error-page__text-center">Here are some helpful links:</Text>

                    <Link to="/terms-of-use">
                        <Text className="error-page__text-center" textDecoration={'underline'} color={'#3182ce'} _hover={{ color: '#233aff' }}>
                            Terms of use
                        </Text>
                    </Link>
                    <Link to="/privacy-policy">
                        <Text className="error-page__text-center" textDecoration={'underline'} color={'#3182ce'} _hover={{ color: '#233aff' }}>
                            Private Policy
                        </Text>
                    </Link>
                    <Link to="/home">
                        <Text className="error-page__text-center" textDecoration={'underline'} color={'#3182ce'} _hover={{ color: '#233aff' }}>
                            Download Application
                        </Text>
                    </Link>
                </Stack> */}
      </Box>

      <Image className='error-page__image' src={ErrorPicture} />
    </Flex>
  );
}

export default NoMatch;
