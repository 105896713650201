import { AbsoluteCenter, Avatar, Box, Divider, Flex, Heading, Skeleton, SkeletonCircle, Tag, TagLabel, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import EachComment from './EachComment';

interface PropsType {
    comments: [];
    isLoading: boolean;
    createdByUserProfileUrl?: string;
    createdByUsername?: string;
    createdAt?: string;
    content?: string;
    tags?: tagType[];
    createdByUserId?: string;
}

interface tagType {
    name: string;
}

interface ItemType {
    createdByUsername: string;
    id: string;
    content: string;
    createdByUserId: string;
    createdAt: string;
    replies: replyType;
    createdByUserProfileUrl: string;
    totalLikes: number;
}

interface replyType {
    replies: [];
}

function Comments({ comments, isLoading, content, createdAt, createdByUserProfileUrl, createdByUsername, tags, createdByUserId }: PropsType) {
    const createdByAuthorId = createdByUserId;

    const getTime = (date: string) => {
        const present = moment();
        const createdTime = moment(date);
        const duration = moment.duration(present.diff(createdTime));

        //get day
        const days = Math.trunc(duration.asDays());
        //get hours
        const hours = Math.trunc(duration.hours());
        //get minutes
        const minutes = Math.trunc(duration.minutes());

        if (days !== 0) {
            return days.toString() + 'd';
        } else if (days === 0 && hours !== 0) {
            return hours.toString() + 'h';
        } else if (days === 0 && hours === 0 && minutes !== 0) {
            return minutes.toString() + 'm';
        } else {
            return 'seconds ago';
        }
    };

    return (
        <VStack spacing={0} align="stretch" bgColor={'white'} height={'30%'} overflowY={'scroll'}>
            {isLoading ? (
                <Box p={4}>
                    <Flex mb={2} alignItems={'center'}>
                        <SkeletonCircle mr={2} />
                        <Skeleton height={'1.5rem'} width={'10rem'} />
                    </Flex>
                    <Skeleton height={'20px'} />
                </Box>
            ) : (
                <>
                    <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'} padding={'16px'}>
                        <Box display={'flex'} alignItems={'start'} pr={'10px'}>
                            <Avatar size={'md'} name={createdByUsername} src={createdByUserProfileUrl ?? ''} mr={'10px'} />
                            <Text fontWeight={700} wordBreak={'break-word'}>
                                <Text>{createdByUsername}</Text>
                                <Text as={'b'} textIndent={'5px'} color={'#818181'} fontWeight={500}>
                                    Author
                                </Text>
                            </Text>
                        </Box>

                        <Text color={'#818181'}>{getTime(createdAt as string)}</Text>
                    </Box>

                    <Text wordBreak={'break-word'} pl={'16px'}>
                        {content}
                    </Text>

                    {tags && tags.length > 0 && (
                        <Box padding={'10px 10px 0px 10px'}>
                            {tags.map((item, index) => (
                                <Tag key={index} size="lg" borderRadius="full" mr={'5px'} mb={'5px'}>
                                    <TagLabel>{item.name}</TagLabel>
                                </Tag>
                            ))}
                        </Box>
                    )}

                    <Box position="relative" padding={3} mt={1}>
                        <Divider />
                        <AbsoluteCenter bg="white" px="4" color={'#818181'}>
                            Comments
                        </AbsoluteCenter>
                    </Box>

                    {comments.length === 0 ? (
                        <Flex height={'100%'} align={'center'} justifyContent={'center'}>
                            <Heading fontSize={'1.3rem'} textAlign={'center'}>
                                No Comments for this post
                            </Heading>
                        </Flex>
                    ) : (
                        <>
                            {comments.map((item: ItemType) => {
                                const time = getTime(item.createdAt);
                                const userAvatar = item.createdByUserProfileUrl;
                                const username = item.createdByUsername;

                                return (
                                    <EachComment
                                        key={item.id}
                                        createdByUserId={item.createdByUserId}
                                        createdByAuthorId={createdByAuthorId}
                                        data={item}
                                        userAvatar={userAvatar}
                                        time={time}
                                        username={username}
                                    />
                                );
                            })}
                        </>
                    )}
                </>
            )}
        </VStack>
    );
}

export default Comments;
