import { Box, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import ScrollToTopButton from '../../../../components/ScrollToTop';
import PrivacyPolicyContent from './PrivacyPolicy.content';
import './PrivacyPolicy.scss';

function PrivacyPolicy() {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);

  const policyKeys: string[] = Object.keys(PrivacyPolicyContent);

  const policyValues: React.ReactNode[] = Object.values(PrivacyPolicyContent);

  const scrollRef = useRef<(HTMLHeadingElement | null)[]>(
    policyKeys.map(() => null)
  );

  const handleScroll = () => {
    const position = window.scrollY;
    setShowScrollButton(position > 250);

    if (showScrollButton) {
      setActiveIndex(-1);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScrollToTerm = (index: number) => {
    setActiveIndex(index);
    const policyElement = scrollRef.current[index];
    if (policyElement) {
      policyElement.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box
      className='privacy-policy__page'
      width={'1600px'}
      my={'100px'}
      mx={'auto'}
    >
      <Box
        className='privacy-policy__page-title'
        as='h2'
        color={'#1F2128'}
        fontSize={'24px'}
        fontWeight={'500'}
        lineHeight={'normal'}
        letterSpacing={'-0.12px'}
        textAlign={'center'}
      >
        THE STYLEDIAL INC. PRIVACY POLICY
      </Box>

      <UnorderedList
        className='privacy-policy__policy-title'
        ml={'0'}
        mt={'100px'}
        color={'#0B0B0B'}
        listStyleType={'none'}
        fontSize={'20px'}
        fontWeight={'400'}
        lineHeight={'normal'}
        letterSpacing={'-0.1px'}
        textDecoration={'underline'}
      >
        {policyKeys.map((item, index) => {
          return (
            <Link key={index} onClick={() => handleScrollToTerm(index)}>
              <ListItem mb={'24px'}>{item}</ListItem>
            </Link>
          );
        })}
      </UnorderedList>

      <UnorderedList
        listStyleType={'none'}
        color={'black'}
        lineHeight={'normal'}
        ml={'0'}
        mt={'100px'}
      >
        {policyKeys.map((item, index) => {
          return (
            <ListItem
              className='privacy-policy__policy'
              key={index}
              mb={'50px'}
            >
              <Text
                ref={(ref) => (scrollRef.current[index] = ref)}
                className={`policy__title ${
                  activeIndex === index && 'active-link'
                }`}
                as='h3'
                mb={'24px'}
                fontSize={'24px'}
                fontWeight={'500'}
                letterSpacing={'-0.12px'}
                pl={'10px'}
              >
                {item}
              </Text>
              <Text
                className='policy__content'
                fontSize={'20px'}
                fontWeight={'400'}
                letterSpacing={'-0.1px'}
              >
                {policyValues[index]}
              </Text>
            </ListItem>
          );
        })}
      </UnorderedList>

      <ScrollToTopButton
        isVisible={showScrollButton}
        onScrollToTop={handleScroll}
      />
    </Box>
  );
}

export default PrivacyPolicy;
