import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import Header from './components/Header/Header';

const TermsAndPolicy = () => {
    return (
        <Box>
            <Header />
            <Box>
                <Outlet />
            </Box>
        </Box>
    );
};

export default TermsAndPolicy;
