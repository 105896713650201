import styledialLogo from "../../assets/styledial.svg";

import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import "./LandingPage.scss";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="landing-page-background flex flex-col min-h-screen justify-evenly">
      <div
        className={clsx(
          "flex flex-col min-[350px]:flex-row gap-6",
          "text-center text-black underline font-medium py-10 text-xl w-full",
          "justify-center min-[550px]:justify-end min-[550px]:pr-[150px]"
        )}
      >
        <span
          className="hover:cursor-pointer"
          onClick={() => navigate("/terms-of-use")}
        >
          Term of Use
        </span>

        <span
          className="hover:cursor-pointer"
          onClick={() => navigate("/privacy-policy")}
        >
          Privacy Policy
        </span>
      </div>

      <div className="flex flex-col flex-wrap gap-20 w-full h-full items-center justify-center px-2 min-[375px]:px-6">
        <img className="w-fit" src={styledialLogo} alt="logo" />

        <div className="flex flex-col text-center text-2xl min-[375px]:text-[34px] font-medium leading-[34px]">
          <span>The best fashion finds</span>

          <span>Style advice when you need it</span>

          <span>From the creators you love</span>

          <span>Ad-free</span>
        </div>

        <div className="flex flex-col gap-10">
          <div className="flex flex-col min-[380px]:flex-row gap-8">
            <a href="https://play.google.com/store/apps/details?id=com.styledial.app.prod&pcampaignid=web_share">
              <button
                className={clsx(
                  "w-full sm:w-[250px] text-center h-fit border border-black border-solid rounded-[100px]",
                  "px-[26px] py-2 font-medium text-xl hover:bg-white duration-300 hover:cursor-pointer",
                  "select-none"
                )}
              >
                Download for Android
              </button>
            </a>
            <a href="https://apps.apple.com/us/app/styledial/id6480029608">
              <button
                className={clsx(
                  "w-full sm:w-[250px] text-center h-fit border border-black border-solid rounded-[100px]",
                  "px-[26px] py-2 font-medium text-xl hover:bg-white duration-300 hover:cursor-pointer",
                  "select-none"
                )}
              >
                Download for iOS
              </button>
            </a>
          </div>

          <div className="flex flex-col gap-4 text-2xl text-black text-center pb-16">
            <span>info@styledial.com</span>
            <span>© 2024 styledial</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
