// ScrollToTopButton.tsx
import { ArrowUpIcon } from '@chakra-ui/icons';
import './ScrollToTopButton.scss';

interface ScrollToTopButtonProps {
  isVisible: boolean;
  onScrollToTop: () => void;
}

function ScrollToTopButton({
  isVisible,
  onScrollToTop,
}: ScrollToTopButtonProps) {
  return (
    <button
      className={`scroll-to-top ${isVisible ? 'show' : ''}`}
      onClick={onScrollToTop}
    >
      <ArrowUpIcon boxSize={6} />
    </button>
  );
}

export default ScrollToTopButton;
