import {
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

const TermsOfUseContent = {
  '1. TERMS OF USE - MOBILE APPLICATION AND WEBSITE': (
    <Text>
      Your use of the styledial Mobile Application and Website are governed by
      these Terms and Conditions of Use as well as the privacy policy
      incorporated herein.
    </Text>
  ),
  '2. ACCEPTANCE OF TERMS AND CONDITIONS OF USE': (
    <Stack spacing={'20px'}>
      <Text>
        Please read the following terms and conditions of use (the "Terms and
        Conditions") carefully before using the Mobile Application and Website.
        The Terms and Conditions for
        <ReactRouterLink
          className='px-1'
          to='/'
          style={{ textDecoration: 'underline' }}
        >
          http://www.styledial.com/
        </ReactRouterLink>
        (the "Website"), and STYLEDIAL Inc’s Mobile Application “STYLEDIAL”
        (“Mobile Application”) constitute a legal agreement and are entered into
        by and between you and STYLEDIAL Inc., its subsidiaries, affiliates and
        related entities ("STYLEDIAL"). These Terms and Conditions exempt
        STYLEDIAL and other persons from liability or limits their liability and
        contains other important provisions that you should read. The following
        terms and conditions, together with any documents and additional terms
        they expressly incorporate by reference, govern your access to and use
        of, including any content, functionality, and services offered on or
        through the Website.
      </Text>

      <Text>
        YOU MAY NOT USE THIS MOBILE APPLICATION OR WEBSITE IF YOU (A) DO NOT
        AGREE TO THESE TERMS, (B) ARE NOT THE OLDER OF (I) AT LEAST 18 YEARS OF
        AGE OR (II) LEGAL AGE TO FORM A BINDING CONTRACT WITH STYLEDIAL, OR (C)
        ARE PROHIBITED FROM ACCESSING OR USING THIS WEBSITE OR ANY OF THIS
        WEBSITE’S CONTENTS, PRODUCTS OR SERVICES BY APPLICABLE LAW.
      </Text>

      <Text>
        EACH TIME YOU USE THE MOBILE APPLICATION OR WEBSITE, WHETHER AS A GUEST
        OR REGISTERED USER, YOU ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH
        THESE TERMS AND CONDITIONS AND OUR PRIVACY POLICY (the "Privacy
        Policy"), INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE
        TERMS AND CONDITIONS OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE
        THE MOBILE APPLICATION OR WEBSITE.
      </Text>

      <Text>WHO WE ARE AND HOW TO CONTACT US</Text>

      <Text>
        We operate the Mobile Application “STYLEDIAL” and the Website
        <ReactRouterLink
          className='pl-1'
          to='/'
          style={{ textDecoration: 'underline' }}
        >
          http://www.styledial.com/
        </ReactRouterLink>
        . We are STYLEDIAL Inc., a company registered in Canada, with our
        registered office and main trading address at 64 Woodlawn Ave W,
        Toronto, ON. M4V1G7.
      </Text>

      <Text>
        If you would like to contact us for any reason, including because you
        have any complaints, please contract info@styledial.com.
      </Text>

      <Text>
        If we have to contact you we will do so by phone, email or prepaid post
        to the address you provide to us. If we have to give you notice in
        writing, we will do so by email or pre-paid post as above.
      </Text>

      <Text>GENERAL</Text>

      <Stack spacing={'0'}>
        <Text>
          All personal information we collect is subject to, and only used in
          accordance with, our Privacy Policy. Please take the time to read our
          Privacy Policy as it includes important terms which apply to you.
        </Text>

        <Text>
          Your use of our Mobile Application or Website is also subject to our
          Mobile Application and Website Terms and Conditions of Use. Please
          take the time to read our Mobile Application and Website Terms and
          Conditions of Use.
        </Text>
      </Stack>
    </Stack>
  ),
  '3. MODIFICATIONS TO THE TERMS AND CONDITIONS AND THE MOBILE APPLICATION OR WEBSITE':
    (
      <Stack spacing={'20px'}>
        <Text>
          STYLEDIAL reserves the right in our sole discretion to revise and
          update these Terms and Conditions from time to time, including the
          Material and/or the products, site-related services and resources
          described therein and reserves the right to make such changes without
          any obligation to notify past, current or prospective users and
          visitors of this site. We reserve the right in our sole discretion to
          revise and update these Terms and Conditions from time to time. Any
          and all such modifications are effective immediately upon posting and
          apply to all access to and continued use of the Mobile Application or
          Website. You agree to periodically review the Terms and Conditions in
          order to be aware of any such modifications and your continued use
          shall be your acceptance of these.
        </Text>

        <Text>
          The information and material on the Mobile Application or Website may
          be changed, withdrawn or terminated at any time in our sole discretion
          without notice. We will not be liable if for any reason all or any
          part of the Mobile Application or Website is restricted to users or
          unavailable at any time or for any period. We may change, suspend or
          end any service, or change and modify prices prospectively at our
          discretion. The Mobile Application, Website, or any related software
          on the Website is not a storage service. You agree that we have no
          obligation to store, maintain or provide you a copy of any content or
          information that you or others provide, except to the extent required
          by applicable law. We may transfer our rights and obligations under
          any order accepted by us to another organization, but this will not
          affect your rights or our obligations under these Terms and
          Conditions.
        </Text>
      </Stack>
    ),
  '4. USE OF THE MOBILE APPLICATION AND WEBSITE: ACCOUNT SET-UP AND SECURITY': (
    <Stack spacing={'20px'}>
      <Text>
        Users are responsible for obtaining their own access to the Mobile
        Application or Website and for their availability and performance. Users
        are required to ensure that all persons who access the Mobile
        Application or Website through a user's internet connection are aware of
        these Terms and Conditions and comply with them. Users are responsible
        for any security breaches or performance issues relating to accessing
        the Website.
      </Text>

      <Text>
        The Mobile Application or Website including content or certain areas may
        require user registration. It is a condition of your use of the Mobile
        Application or Website that all the information you provide on the
        Mobile Application or Website is correct, current, and complete.
      </Text>

      <Text>
        Your provision of registration information and any submissions you make
        to the Mobile Application or Website through any functionality such as
        applications, e.g. chat functions, e-mail, message boards, personal or
        interest group web pages, profiles, forums, bulletin boards and other
        such functions (collectively, "Interactive Functions")], constitutes
        your consent to all actions we take with respect to such information
        consistent with our Privacy Policy.
      </Text>

      <Text>
        Any user name, password, or any other piece of information chosen by you
        or provided to you as part of our security procedures, must be treated
        as confidential, and you must not disclose it to any other person or
        entity. You must exercise caution when accessing your account from a
        public or shared computer so that others are not able to view or record
        your password or other personal information. You understand and agree
        that should you be provided an account, your account is personal to you
        and you agree not to provide any other person with access to this
        Website or portions of it using your user name, password, or other
        security information. You agree to notify us immediately of any
        unauthorized access to or use of your user name or password or any other
        breach of security. You also agree to ensure that you logout from your
        account at the end of each session. You are responsible for any password
        misuse or any unauthorized access.
      </Text>

      <Text>
        We reserve the right at any time and from time to time, to disable or
        terminate your account, any user name, password, or other identifier,
        whether chosen by you or provided by us, in our sole discretion for any
        or no reason, including any violation of any provision of these Terms
        and Conditions.
      </Text>

      <Text>
        You are prohibited from attempting to circumvent and from violating the
        security of this Mobile Application or Website including without
        limitation: (a) accessing content and data that is not intended for you;
        (b) attempting to breach or breaching the security and/or authentication
        measures which are not authorized; (c) restrict disrupt or disable
        service to users, hosts, servers or networks; (d) illicitly reproducing
        TCP/IP packet header; (e) disrupting network services and otherwise
        disrupting Mobile Application or Website owner's ability to monitor the
        Mobile Application or Website; (f) use any robot, spider, or other
        automatic device, process, or means to access the Mobile Application or
        Website for any purpose, including monitoring or copying any of the
        material; (g) introduce any viruses, trojan horses, worms, logic bombs,
        or other material that is malicious or technologically harmful; (h)
        attack the Mobile Application or Website via a denial-of-service attack,
        distributed denial-of-service attack, flooding, mail-bombing or
        crashing; and (i) otherwise attempt to interfere with the proper working
        of the Mobile Application or Website.
      </Text>
    </Stack>
  ),
  '5. CONDITIONS OF USE AND USER SUBMISSIONS AND CONTENT STANDARDS': (
    <Stack spacing={'20px'}>
      <Text>
        As a condition of your access and use you agree that you may use the
        Mobile Application or Website only for lawful purposes and in accordance
        with these Terms and Conditions.
      </Text>

      <Text>
        The following content standards apply to any and all content, material,
        and information a user submits, posts, publishes, displays, or transmits
        (collectively, "Submit") to the Mobile Application or Website, to other
        users or other persons (collectively, "User Submissions") and any and
        all Interactive Functions. Any and all User Submissions must comply with
        all applicable federal, provincial, local, and international laws and
        regulations.
      </Text>

      <Text>
        Without limiting the foregoing you warrant and agree that your use of
        the Mobile Application or Website and any User Submissions shall not:
      </Text>

      <UnorderedList className='term-list' pl={'40px'}>
        <ListItem>
          In any manner violate any applicable federal, provincial, local, or
          international law or regulation including, without limitation, any
          laws regarding the export of data or software, patent, trademark,
          trade secret, copyright, or other intellectual property, legal rights
          (including the rights of publicity and privacy of others) or contain
          any material that could give rise to any civil or criminal liability
          under applicable laws or regulations or that otherwise may be in
          conflict with these Terms and Conditions and our Privacy Policy.
        </ListItem>

        <ListItem>
          Access without authority, interfere with, damage, or disrupt any part
          of the Mobile Application or Website, any equipment or network on
          which the Mobile Application or Website is stored, any software used
          in the provision of the Mobile Application or Website, or any
          equipment or network or software owned or used by any third party.
        </ListItem>

        <ListItem>
          Include or contain any material that is exploitive, obscene, harmful,
          threatening, abusive, harassing, hateful, defamatory, sexually
          explicit or pornographic, violent, inflammatory, or discriminatory
          based on race, sex, religion, nationality, disability, sexual
          orientation, or age or other such prohibited ground or be otherwise
          objectionable.
        </ListItem>

        <ListItem>
          Involve stalking, attempting to exploit any individual or harm minors
          in any way by exposing them to inappropriate content or otherwise nor
          ask for personal information.
        </ListItem>

        <ListItem>
          Involve, provide or contribute any false, inaccurate or misleading
          information.
        </ListItem>

        <ListItem>
          Include sending, knowingly receiving, uploading, downloading, using,
          or reusing any material that does not comply with the User Submissions
          and site content standards set out in these Terms and Conditions.
        </ListItem>

        <ListItem>
          Impersonate or attempt to impersonate STYLEDIAL, a Company employee,
          another user, or any other person or entity (including, without
          limitation, by using email addresses, or screen names associated with
          any of the foregoing).
        </ListItem>

        <ListItem>
          Transmit, or procure the sending of, any advertisements or promotions
          without our prior written consent, commercial activities or sales,
          including without limitation any "spam", "junk mail", "chain letter",
          contests, sweepstakes and other sales promotions, barter, or
          advertising or any other similar solicitation.
        </ListItem>

        <ListItem>
          Include engaging in any other conduct that restricts or inhibits
          anyone's use or enjoyment of the WebsiteMobile Application or Website,
          or which, as determined by us, may harm STYLEDIAL or users of the
          Mobile Application or Website or expose them to liability.
        </ListItem>

        <ListItem>
          Include causing annoyance, inconvenience, or needless anxiety or be
          likely to upset, embarrass, or alarm any other person.
        </ListItem>

        <ListItem>
          Promote any illegal activity, fraud, or advocate, promote, or assist
          any unlawful or fraudulent act.
        </ListItem>

        <ListItem>
          Give the impression that they originate from or are endorsed by us or
          any other person or entity, if this is not the case.
        </ListItem>
      </UnorderedList>
    </Stack>
  ),
  '6. USER COMMUNICATIONS AND SUBMISSIONS: GRANT OF LICENSE': (
    <Stack spacing={'20px'}>
      <Text>
        The Mobile Application or Website may contain Interactive Functions
        allowing User Submissions on or through the Mobile Application or
        Website. However, please be aware that any User Submissions and/or
        information, including, but not limited to, comments, suggestions,
        feedback data or the like regarding the site, the Material or STYLEDIAL
        and its products and services ("Information") shall be deemed to be non-
        confidential and STYLEDIAL shall have no obligation of any kind with
        respect to such Information. In addition, STYLEDIAL shall be free to
        reproduce, use, disclose, display, exhibit, transmit, perform, create
        derivative works and distribute the Information to others without
        limitation and to authorize others to do the same. Such Information
        shall remain the property of STYLEDIAL and STYLEDIAL shall be free to
        use any ideas, concepts, submissions, know-how or techniques contained
        in such Information for any purpose whatsoever, including, but not
        limited to, developing, manufacturing and marketing products and other
        items incorporating such Information without compensation to the
        provider of such Information. You agree not to assert any ownership
        right of any kind in any Information provided to STYLEDIAL (including,
        but not limited to, copyright, trademark, unfair competition, moral
        rights, or implied contract) and to waive the right to receive any
        financial or other consideration in connection with such Information
        including, but not limited to, your acknowledgement.
      </Text>

      <Text>
        Without limiting the generality of the foregoing, by providing any
        Information to us, you grant us and our affiliates and service
        providers, and each of their respective licensees, successors, and
        assigns the right to a world-wide, royalty free, perpetual, irrevocable,
        non-exclusive license to use, reproduce, modify, perform, display,
        distribute, and otherwise disclose to third parties any such material
        for any purpose and according to your account settings and/or
        incorporate such material into any form, medium or technology throughout
        the world without compensation to you. You waive any moral rights or
        other rights of authorship as a condition of submitting any User
        Submission.
      </Text>

      <Text>
        By submitting the Information you declare and warrant that you own or
        have the necessary rights to submit the Information and have the right
        to grant the license hereof to us and our affiliates and service
        providers, and each of their respective licensees, successors, and
        assigns to the Information and comply with these Terms and Conditions.
        You represent and warrant that all Information comply with applicable
        laws and the Information and Site Content Standards set out in these
        Terms and Conditions.
      </Text>

      <Text>
        You understand and agree that you, not STYLEDIAL, are fully responsible
        for any Information you submit or contribute, and you are fully
        responsible and legally liable including to any third party for such
        content and its accuracy. We are not responsible or legally liable to
        any third party for the content or accuracy of any Information submitted
        by you or any other user of the Mobile Application or Website.
      </Text>
    </Stack>
  ),
  '7. SITE MONITORING AND ENFORCEMENT, SUSPENSION AND TERMINATION': (
    <Stack spacing={'20px'}>
      <Text>Company has the right, without provision of notice to:</Text>

      <OrderedList className='term-list' pl={'30px'}>
        <ListItem>
          Remove or refuse to post on the Mobile Application or Website any User
          Submissions for any or no reason in our sole discretion.
        </ListItem>

        <ListItem>
          At all times, to take such actions with respect to any User Submission
          deemed necessary or appropriate in our sole discretion, including
          without limitation, for violating the Mobile Application or Website
          and User Submissions and site content standards or Terms and
          Conditions.
        </ListItem>

        <ListItem>
          Take appropriate legal action, including without limitation, referral
          to law enforcement, regulatory authority or harmed party for any
          illegal or unauthorized use of the Mobile Application or Website.
          Without limiting the foregoing, we have the right to fully cooperate
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Mobile Application or Website.
          We may initiate legal proceedings against you for reimbursement of all
          costs on an indemnity basis (including, but not limited to, reasonable
          administrative and legal costs) resulting from the breach.
        </ListItem>

        <ListItem>
          Terminate or suspend your access to all or part of the Mobile
          Application or Website for any or no reason, including without
          limitation, any violation of these Terms and Conditions.
        </ListItem>
      </OrderedList>

      <Text>
        YOU WAIVE AND HOLD HARMLESS STYLEDIAL AND ITS AFFILIATES, LICENSEES AND
        SERVICE PROVIDERS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION
        TAKEN BY STYLEDIAL AND ANY OF THE FOREGOING PARTIES RELATING TO ANY
        INVESTIGATIONS BY EITHER STYLEDIAL OR SUCH PARTIES OR BY LAW ENFORCEMENT
        AUTHORITIES.
      </Text>

      <Text>
        We have no obligation, nor any responsibility to any party to monitor
        the Mobile Application or Website or use, and do not and cannot
        undertake to review material that you or other users submit to the
        Mobile Application or Website. We cannot ensure prompt removal of
        objectionable material after it has been posted and we have no liability
        for any action or inaction regarding transmissions, communications, or
        content provided by any user or third party.
      </Text>
    </Stack>
  ),
  '8. NO RELIANCE': (
    <Stack spacing={'20px'}>
      <Text>
        The content on our Mobile Application or Website is provided for general
        information purposes only. It is not intended to amount to advice on
        which you should rely. You must obtain more specific or professional
        advice before taking, or refraining from, any action or inaction on the
        basis of the content on our site. Any information provided by STYLEDIAL
        is for informational purposes only and is not meant to be a substitute
        for advice provided by a qualified health care professional. You are
        responsible for your own health and safety at all times and should
        consult with a qualified health care professional for any medical advice
        or information required.  
      </Text>

      <Text>
        Although we make reasonable efforts to update the information on our
        Mobile Application or Website, we make no representations, warranties or
        guarantees, whether express or implied, that the content on our Mobile
        Application or Website is accurate, complete or up to date. Your use of
        the Mobile Application or Website is at your own risk and STYLEDIAL has
        no responsibility or liability whatsoever for your use of this Mobile
        Application or Website.
      </Text>

      <Text>
        This Mobile Application or Website may include content provided by third
        parties, including from other users and third-party licensors. All
        statements and/or opinions expressed in any such third party content,
        other than the content provided by STYLEDIAL, are solely the opinions
        and the responsibility of the person or entity providing those
        materials. Such materials do not necessarily reflect the opinion of
        STYLEDIAL. STYLEDIAL is not responsible, or liable to you or any third
        party, for the content or accuracy of any third party materials.
      </Text>
    </Stack>
  ),
  '9. PRIVACY': (
    <Stack spacing={'20px'}>
      <Text>
        Your use of our Mobile Application or Website constitutes consent to the
        collection, use, reproduction, hosting, transmission and disclosure of
        any personal information in accordance with our Privacy Policy.
      </Text>

      <Text>
        By using this Website you are consenting to the use of cookies which
        allow a server to recall previous requests or registration and/or IP
        addresses to analyze Website use patterns in compliance with our Privacy
        Policy. You can set your browser to notify you before you receive a
        cookie, giving you the chance to decide whether to accept it. You can
        also set your browser to turn off cookies. If you do, however, some
        areas of the Website may not function adequately.
      </Text>
    </Stack>
  ),
  '10. THIRD PARTY WEBSITES AND LINKS TO THIS SITE': (
    <Stack spacing={'20px'}>
      <Text>
        For your convenience, this Mobile Application or Website may provide
        links or pointers to third party sites. We make no representations about
        any other Mobile Application or Websites that may be accessed from this
        Mobile Application or Website. If you choose to access any such sites,
        you do so at your own risk. We have no control over the contents of any
        such third party sites, and accept no responsibility for such sites or
        for any loss or damage that may arise from your use of them. You are
        subject to any Terms and Conditions of such third party sites.
      </Text>

      <Text>
        Such links to third party sites from the Mobile Application or Website,
        may include links to certain social media features that enable you to
        link or transmit on your own or using certain third party Mobile
        Applications or Websites, certain limited content from this Mobile
        Application or Website. You may only use these features when they are
        provided by us and solely with respect to the content identified. Such
        features and links to third party sites are subject to any additional
        Terms and Conditions we provide with respect to such features.
      </Text>

      <Text>
        You may link to our homepage, provided you do so in a way that is fair
        and legal and does not damage our reputation or take advantage of it.
        You must not establish a link in such a way as to suggest any form of
        association, approval or endorsement on our part where none exists. Our
        site must not be framed on any other site, nor may you create a link to
        any part of our site other than the homepage. We reserve the right to
        withdraw linking permission without notice. The Mobile Application or
        Website in which you are linking must comply in all respects with the
        conditions of use and User Submissions and Site Content Standards. You
        agree to cooperate with us in causing any unauthorized framing or
        linking to immediately stop.
      </Text>

      <Text>
        STYLEDIAL does not review the sites or Mobile Applications that link to
        this Mobile Application or Website and is not responsible for any of
        those sites or the contents or materials of those sites that link to
        this site. Your linking to any other sites or pages is at your own risk.
        We make no warranties or representations, express or implied, whether by
        statute, common law, custom, usage or otherwise, as to third party
        content including, without limitation, non-infringement of third party
        rights, title, integration, risks of program errors, corruption,
        viruses, hacking, intrusions, damage to equipment, loss of data, or
        unavailability or interruption of the Website Mobile Application or
        Website or operations, however caused, accuracy, availability,
        satisfactory quality, and merchantability or fitness for any particular
        purpose.
      </Text>
    </Stack>
  ),
  '11. GOVERNING LAW, JURISDICTION, AND GEOGRAPHIC RESTRICTIONS': (
    <Text>
      STYLEDIAL controls and operates this Mobile Application and Website from
      its headquarters in Toronto, Ontario, Canada. STYLEDIAL makes no
      representation that the Mobile Application or Website or the Materials are
      appropriate or available for use in other locations. If you use this site
      from other locations, you are responsible for complying with applicable
      local laws. If you access this site from outside Canada, you do so at your
      own risk and you are responsible for compliance with applicable laws of
      your jurisdiction. The Terms and Conditions are entered into in the
      Province of Ontario and shall be governed by and construed in accordance
      with the laws of the Province of Ontario and the laws of Canada, exclusive
      of its choice of law rules. Each party to the Terms and Conditions submits
      to the exclusive jurisdiction of the Federal Court of Canada and/or the
      courts of the Province of Ontario, and waives any jurisdictional venue or
      inconvenient forum objections to such courts. In any action to enforce the
      Terms and Conditions, the prevailing party will be entitled to costs and
      attorneys' fees. In the event that any of the provisions of the Terms and
      Conditions are held by a court or other tribunal of competent jurisdiction
      to be unenforceable, such provisions shall be limited or eliminated to the
      minimum extent necessary so that the Terms and Conditions shall otherwise
      remain in full force and effect.
    </Text>
  ),
  '12. DISCLAIMER OF WARRANTIES': (
    <Stack spacing={'20px'}>
      <Text>
        YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE MOBILE APPLICATION OR
        WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED
        THROUGH THE MOBILE APPLICATION OR WEBSITE IS AT YOUR OWN RISK. THE
        MOBILE APPLICATION OR WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
        FOUND OR ATTAINED THROUGH THE MOBILE APPLICATION OR WEBSITE ARE PROVIDED
        ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY
        KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT
        CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </Text>

      <Text>
        NEITHER STYLEDIAL NOR ANY AFFILIATES NOR THEIR RESPECTIVE DIRECTORS,
        OFFICERS, EMPLOYEES, AGENTS OR SERVICE PROVIDERS MAKES ANY WARRANTY,
        REPRESENTATION OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS,
        SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY OR AVAILABILITY
        OF THE MOBILE APPLICATION OR WEBSITE OR ITS CONTENTS. WITHOUT LIMITING
        THE FOREGOING, NEITHER STYLEDIAL NOR ANY AFFILIATES NOR THEIR RESPECTIVE
        DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR SERVICE PROVIDERS REPRESENTS
        OR WARRANTS THAT THE MOBILE APPLICATION OR WEBSITE, ITS CONTENT, OR ANY
        SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE MOBILE APPLICATION OR
        WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
        DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
        AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT
        COMMUNICATIONS TO OR FROM THIS SITE WILL BE SECURE OR NOT INTERCEPTED.
        YOU ASSUME TOTAL RESPONSIBILITY, COST AND RISK FOR YOUR USE OF THIS
        SITE, THE MATERIAL AND SITE-RELATED SERVICES.
      </Text>

      <Text>
        WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE
        FOR DOWNLOADING FROM THE INTERNET OR THE MOBILE APPLICATION OR WEBSITE
        WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND
        ENTIRELY RESPONSIBLE FOR YOUR USE OF THE MOBILE APPLICATION OR WEBSITE
        AND YOUR MOBILE PHONE, COMPUTER, INTERNET AND DATA SECURITY. TO THE
        FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR
        DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED DENIAL-OF-SERVICE
        ATTACK, OVERLOADING, FLOODING, MAIL-BOMBING OR CRASHING, VIRUSES, TROJAN
        HORSES, WORMS, LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL
        THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR
        OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE MOBILE APPLICATION OR
        WEBSITE OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE MOBILE
        APPLICATION OR WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON
        IT, OR ON ANY MOBILE APPLICATION OR WEBSITE LINKED TO IT.
      </Text>
    </Stack>
  ),
  '13. LIMITATION OF LIABILITY': (
    <Text>
      UNDER NO CIRCUMSTANCE WILL STYLEDIAL, ITS AFFILIATES AND THEIR RESPECTIVE
      DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, LICENSORS, SERVICE PROVIDERS, OR
      THEIR RESPECTIVE SUCCESSORS OR ASSIGNS BE LIABLE FOR NEGLIGENCE, GROSS
      NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF
      ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
      INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
      TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
      REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF
      USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
      NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY OR OTHERWISE, EVEN IF
      ANA STYLEDIAL OR AN AUTHORIZED STYLEDIAL REPRESENTATIVE WAS ALLEGEDLY
      ADVISED, HAD REASON TO KNOW, OR OUGHT TO HAVE KNOWN ABOUT THE POSSIBILITY
      OF DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
      USE, RELIANCE ON, THE MOBILE APPLICATION OR WEBSITE, ANY LINKED MOBILE
      APPLICATIONS OR WEBSITES OR SUCH OTHER THIRD PARTY MOBILE APPLICATIONS OR
      WEBSITES, NOR ANY SITE CONTENT, MATERIALS, POSTING OR INFORMATION THEREON.
      YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE MOBILE APPLICATION, WEBSITE
      AND/OR WEBSITE-RELATED SERVICES IS TO STOP USING THE MOBILE APPLICATION,
      WEBSITE AND/OR THOSE SERVICES. IN NO EVENT SHALL STYLEDIAL, ITS OFFICERS,
      DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, OR THEIR RESPECTIVE SUCCESSORS OR
      ASSIGNS BE LIABLE FOR ANY LOSS OR DAMAGE ARISING FROM THE FAILURE OF
      STYLEDIAL TO PROTECT YOUR PASSWORD OR ACCOUNT INFORMATION. YOU HEREBY
      RELEASE STYLEDIAL, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS,
      OR THEIR RESPECTIVE SUCCESSORS OR ASSIGNS FROM ANY DAMAGES THAT YOU INCUR,
      AND AGREE NOT TO ASSERT ANY CLAIMS AGAINST THEM, ARISING FROM YOUR
      PURCHASE OR USE OF ANY PRODUCTS OR SERVICES MADE AVAILABLE THROUGH THIS
      SITE.
    </Text>
  ),
  '14. INDEMNIFICATION': (
    <Text>
      To the maximum extent permitted by applicable law, you agree to defend,
      indemnify, and hold harmless STYLEDIAL, its affiliates, and their
      respective directors, officers, employees, agents, service providers,
      contractors, licensors, suppliers, successors, and assigns from and
      against any claims, liabilities, damages, judgments, awards, losses,
      costs, expenses, or fees (including reasonable attorneys' fees) arising
      out of or relating to your breach of the Terms and Conditions or your use
      of the Mobile Application or Website, including, but not limited to, your
      User Submissions, Information, third party sites, any use of the Mobile
      Application or Website's content, services, and products other than as
      expressly authorized in these Terms and Conditions.
    </Text>
  ),
  '15. WAIVER': (
    <Text>
      No waiver under these Terms and Conditions is effective unless it is in
      writing and signed by an authorized representative of the party waiving
      its right. No failure to exercise, or delay in exercising, any right,
      remedy, power or privilege arising from these Terms and Conditions
      operates, or may be construed, as a waiver thereof. No single or partial
      exercise of any right, remedy, power or privilege hereunder precludes any
      other or further exercise thereof or the exercise of any other right,
      remedy, power or privilege.
    </Text>
  ),
  '16. SEVERABILITY': (
    <Text>
      If any term or provision of these Terms and Conditions is invalid, illegal
      or unenforceable in any jurisdiction, such invalidity, illegality or
      unenforceability shall not affect any other term or provision of these
      Terms and Conditions or invalidate or render unenforceable such term or
      provision in any other jurisdiction.
    </Text>
  ),
  '17. GENERAL': (
    <Text>
      Any consent by STYLEDIAL to, or waiver of, a breach of these Terms and
      Conditions of Use which you have committed, whether express or implied,
      shall not constitute a consent to, or waiver of, any other, different, or
      subsequent breach. STYLEDIAL may assign its rights and duties hereunder to
      any party at any time without any notice to you. You may not assign your
      rights or obligations hereunder without STYLEDIAL prior written consent.
    </Text>
  ),
  '18. ENTIRE AGREEMENT': (
    <Text>
      These Terms and Conditions and , our Privacy Policy are hereby expressly
      incorporated herein, and constitute the sole and entire agreement between
      you and STYLEDIAL Inc regarding the Mobile Application and Website and
      supersedes all prior and contemporaneous understandings, agreements,
      representations and warranties, both written and oral, regarding such
      subject matter.
    </Text>
  ),
};

export default TermsOfUseContent;
