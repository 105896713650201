import { Box, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import ScrollToTop from '../../../../components/ScrollToTop';
import TermsOfUseContent from './TermsOfUse.content';
import './TermsOfUse.scss';

function TermsOfUse() {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);

  const termKeys: string[] = Object.keys(TermsOfUseContent);

  const termValues: React.ReactNode[] = Object.values(TermsOfUseContent);

  const scrollRef = useRef<(HTMLHeadingElement | null)[]>(
    termKeys.map(() => null)
  );

  const handleScroll = () => {
    const position = window.scrollY;
    setShowScrollButton(position > 250);

    if (showScrollButton) {
      setActiveIndex(-1);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  const handleScrollToTerm = (index: number) => {
    setActiveIndex(index);
    const termElement = scrollRef.current[index];

    if (termElement) {
      termElement.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      className='terms-of-use__page'
      width={'1600px'}
      my={'100px'}
      mx={'auto'}
    >
      <Box
        className='terms-of-use__page-title'
        as='h2'
        color={'#1F2128'}
        fontSize={'24px'}
        fontWeight={'500'}
        lineHeight={'normal'}
        letterSpacing={'-0.12px'}
        textAlign={'center'}
      >
        THE STYLEDIAL INC. WEBSITE MOBILE APPLICATION AND WEBSITE TERMS AND
        CONDITIONS OF USE
      </Box>

      <UnorderedList
        className='terms-of-use__term-title'
        ml={'0'}
        mt={'100px'}
        color={'#0B0B0B'}
        listStyleType={'none'}
        fontSize={'20px'}
        fontWeight={'400'}
        lineHeight={'normal'}
        letterSpacing={'-0.1px'}
        textDecoration={'underline'}
      >
        {termKeys.map((item, index) => {
          return (
            <ListItem mb={'24px'} key={index}>
              <Link onClick={() => handleScrollToTerm(index)}>{item}</Link>
            </ListItem>
          );
        })}
      </UnorderedList>
      <UnorderedList
        listStyleType={'none'}
        color={'black'}
        lineHeight={'normal'}
        ml={'0'}
        mt={'100px'}
      >
        {termKeys.map((item, index) => {
          return (
            <ListItem key={index} mb={'50px'}>
              <Text
                ref={(ref) => (scrollRef.current[index] = ref)}
                className={
                  activeIndex === index
                    ? 'active-link terms-of-use__content-title'
                    : 'terms-of-use__content-title'
                }
                as='h3'
                mb={'24px'}
                fontSize={'24px'}
                fontWeight={'500'}
                letterSpacing={'-0.12px'}
                pl={'10px'}
              >
                {item}
              </Text>
              <Text
                className='terms-of-use__content'
                fontSize={'20px'}
                fontWeight={'400'}
                letterSpacing={'-0.1px'}
              >
                {termValues[index]}
              </Text>
            </ListItem>
          );
        })}
      </UnorderedList>

      <Text
        color={'black'}
        fontSize={'20px'}
        fontWeight={'400'}
        lineHeight={'normal'}
        letterSpacing={'-0.1px'}
      >
        © 2024 styledial inc.
      </Text>

      <ScrollToTop isVisible={showScrollButton} onScrollToTop={handleScroll} />
    </Box>
  );
}

export default TermsOfUse;
